import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import TimeAgo from "timeago-react";
import Seo from "../components/Seo";

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data; // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark;
  console.log(frontmatter.authorImg);
  return (
    <>
      <Seo title={frontmatter.title} />
      <div>
        <div className="bg-white shadow-lg border-b border-gray-200 max-w-4xl my-8 lg:mx-48">
          <div className="border-b-2">
            <div className="p-4 lg:p-8 lg:pb-3">
              <h1 className="text-3xl tracking-tight font-extrabold text-purple-600 md:text-4xl xl:text-4xl">
                {frontmatter.title}
              </h1>
              <div className="flex justify-between items-center mt-2">
                <div className="flex items-center">
                  <GatsbyImage
                    className="h-10 w-10 mr-1 rounded-full inline"
                    image={getImage(frontmatter.authorImg)}
                    alt=""
                  />
                  <h2 className="inline">{frontmatter.author}</h2>
                </div>
                <TimeAgo className="italic" datetime={frontmatter.date} />
              </div>
              <div className="mt-4 flex items-center">
                <a
                  href={`https://www.facebook.com/sharer/sharer.php?u=https://envizionlabs.com${frontmatter.slug}/`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="inline mx-0.5"
                    src={"/facebook.svg"}
                    height={40}
                    width={40}
                    alt="Statamic"
                  />
                </a>
                <span
                  className="mx-0.5"
                  style={{ display: `inline-block`, position: `relative` }}
                >
                  <img
                    className="inline"
                    src={"/twitter-circle.svg"}
                    height={40}
                    width={40}
                    alt="Statamic"
                  />
                  <img
                    style={{
                      bottom: 0,
                      left: 0,
                      margin: `auto`,
                      position: `absolute`,
                      right: 0,
                      top: 0,
                      height: `70%`,
                      width: `70%`,
                    }}
                    className="inline"
                    src={"/twitter.svg"}
                    height={40}
                    width={40}
                    alt="Statamic"
                  />
                </span>
                <a
                  href={`http://www.reddit.com/submit?url=https://envizionlabsmaster.gatsbyjs.io${frontmatter.slug}/&title=${frontmatter.title}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="inline mx-0.5"
                    src={"/reddit.svg"}
                    height={40}
                    width={40}
                    alt="Statamic"
                  />
                </a>
                <span
                  className="mx-0.5"
                  style={{ display: `inline-block`, position: `relative` }}
                >
                  <img
                    className="inline"
                    src={"/whatsapp-circle.svg"}
                    height={40}
                    width={40}
                    alt="Statamic"
                  />
                  <img
                    style={{
                      bottom: 0,
                      left: 0,
                      margin: `auto`,
                      position: `absolute`,
                      right: 0,
                      top: 0,
                      height: `70%`,
                      width: `70%`,
                    }}
                    className="inline"
                    src={"/whatsapp.svg"}
                    height={40}
                    width={40}
                    alt="Statamic"
                  />
                </span>
                <span className="share-text ml-1">SHARE</span>
              </div>
            </div>
          </div>
          <GatsbyImage
            image={getImage(frontmatter.imageUrl)}
            alt=""
            className="block mt-8 mx-auto max-w-3xl"
          />
          <div
            dangerouslySetInnerHTML={{ __html: html }}
            className="text-lg py-6 px-4 lg:px-12 blog-text"
          ></div>
        </div>
      </div>
    </>
  );
}

export const pageQuery = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
        author
        authorImg {
          childImageSharp {
            gatsbyImageData(width: 40, height: 40, quality: 100)
          }
        }
        imageUrl {
          childImageSharp {
            gatsbyImageData(width: 600, quality: 100)
          }
        }
      }
    }
  }
`;
